import clsx from 'clsx';
import React, { InputHTMLAttributes, TextareaHTMLAttributes } from 'react';

type CommonInputProps =
  | 'onChange'
  | 'value'
  | 'required'
  | 'name'
  | 'maxLength'
  | 'placeholder';

type HtmlProps<T extends boolean = boolean> = {
  multiline?: T;
} & Pick<TextareaHTMLAttributes<HTMLTextAreaElement>, CommonInputProps> &
  Pick<InputHTMLAttributes<HTMLInputElement>, CommonInputProps | 'type'>;

export type InputProps = {
  id: string;
  isAttached?: boolean;
  className?: string;
  label?: string;
} & HtmlProps;

const Input = React.forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  InputProps
>(
  (
    {
      id,
      name,
      className,
      isAttached = false,
      label,
      multiline = false,
      ...restProps
    },
    ref,
  ) => {
    const fieldClassName = clsx(
      'rounded border border-gray-300 shadow-sm',
      multiline ? 'px-4 py-2' : 'px-4 h-12',
      'w-full block',
      {
        'rounded-r-none': isAttached,
      },
      className,
    );
    return (
      <>
        {label && (
          <label className="block mb-1 font-medium" htmlFor={name || id}>
            {label}
          </label>
        )}
        {multiline ? (
          <textarea
            className={fieldClassName}
            id={id}
            name={name || id}
            rows={3}
            maxLength={300}
            ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
            {...restProps}
          />
        ) : (
          <input
            className={fieldClassName}
            id={id}
            name={name || id}
            ref={ref as React.ForwardedRef<HTMLInputElement>}
            {...restProps}
          />
        )}
      </>
    );
  },
);

export default Input;
