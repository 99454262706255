import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';

const isDev = process.env.NODE_ENV !== 'production';

if (!isDev) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    serverRuntimeConfig,
    publicRuntimeConfig,
  }: {
    serverRuntimeConfig: { BUGSNAG_API_KEY: string };
    publicRuntimeConfig: { BUGSNAG_API_KEY: string };
  } = getConfig();

  Bugsnag.start({
    apiKey:
      serverRuntimeConfig.BUGSNAG_API_KEY ||
      publicRuntimeConfig.BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
  });
}

const PassThroughElement = ({ children }: { children: React.ReactNode }) => (
  <>{children}</>
);
export const ErrorBoundary =
  (!isDev && Bugsnag.getPlugin('react')?.createErrorBoundary(React)) ||
  PassThroughElement;

export default isDev
  ? {
      notify: (e: Error) => {
        throw e;
      },
    }
  : Bugsnag;
