import React from 'react';
import clsx from 'clsx';
import Button from ':src/components/Button';
import useGitHubLogin from ':src/utils/useGitHubLogin';

export type GithubButtonProps = {
  actionText: string;
  mobile?: boolean;
};

export default function GithubButton({
  actionText,
  mobile = false,
}: GithubButtonProps) {
  const { login } = useGitHubLogin();

  const iconClassName = clsx({
    'w-6': mobile,
  });

  return (
    <Button
      fullWidth
      startIcon={
        <img className={iconClassName} src="/oauth/github.svg" alt="" />
      }
      color="slate"
      onClick={login}
    >
      {actionText} with Github
    </Button>
  );
}
