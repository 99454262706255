import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import NewsletterSignup from ':src/modules/global/NewsletterSignup';

const LINKS = [
  { text: 'Contact', href: '/contact' },
  { text: 'Legal', href: '/legal' },
  { text: 'Privacy policy', href: '/privacy' },
];

export default function Footer() {
  const { asPath } = useRouter();
  return (
    <div>
      {!asPath.startsWith('/newsletter') && <NewsletterSignup />}
      <footer className="border-t-2 border-brand-200 bg-brand-100 text-brand-900 py-6">
        <div className="container flex justify-between">
          <div>© 2022 Frontendeval</div>
          <nav className="space-x-4 md:space-x-6">
            {LINKS.map(({ text, href }) => (
              <Link href={href} key={href}>
                <a className="underline hover:no-underline">{text}</a>
              </Link>
            ))}
          </nav>
        </div>
      </footer>
    </div>
  );
}
