import { nanoid } from 'nanoid';
import { useRouter } from 'next/router';
import { GITHUB_STATE_LOCALSTORAGE_KEY } from ':src/constants';
import * as gtag from ':src/lib/gtag';

export default function useGitHubLogin() {
  const { asPath } = useRouter();

  const login = () => {
    gtag.event({
      action: 'login_click',
      category: 'login',
      label: 'Click login button',
      value: '1',
    });
    localStorage.redirectTo = asPath.startsWith('/github-callback')
      ? '/'
      : asPath;
    const state = nanoid();
    localStorage.setItem(GITHUB_STATE_LOCALSTORAGE_KEY, state);
    const url = new URL('https://github.com/login/oauth/authorize');
    url.searchParams.append(
      'client_id',
      process.env.NEXT_PUBLIC_GITHUB_CLIENT_ID!,
    );
    url.searchParams.append(
      'redirect_uri',
      `${window.location.origin}/github-callback`,
    );
    url.searchParams.append('scope', 'user:email');
    url.searchParams.append('state', state);
    url.searchParams.append('allow_signup', 'false');
    window.location.href = url.href;
  };
  return { login };
}
