export const GITHUB_STATE_LOCALSTORAGE_KEY = 'github-state';
export const FACEBOOK_STATE_LOCALSTORAGE_KEY = 'facebook-state';
export const SURVEY_LOCALSTORAGE_KEY = 'survey';
export const MAX_SOLUTIONS = 999;

export enum DIFFICULTY {
  All = 'All',
  Hard = 'Hard',
  Medium = 'Medium',
  Easy = 'Easy',
}

export const DIFFICULTY_OPTIONS = [
  {
    value: DIFFICULTY.All,
    display: DIFFICULTY.All,
    disabled: false,
  },
  {
    value: DIFFICULTY.Easy,
    display: DIFFICULTY.Easy,
    disabled: false,
  },
  {
    value: DIFFICULTY.Medium,
    display: DIFFICULTY.Medium,
    disabled: false,
  },
  {
    value: DIFFICULTY.Hard,
    display: DIFFICULTY.Hard,
    disabled: false,
  },
];
