import React from 'react';
import Footer from './Footer';
import Nav from './Nav';
import useFeatureFlags from ':src/hooks/useFeatureFlags';

export default function Layout({ children }: { children: React.ReactNode }) {
  const { isMVPLaunched } = useFeatureFlags();
  return (
    <div className="h-full flex flex-col">
      <Nav />
      <main className="flex-1">{children}</main>
      {isMVPLaunched && <Footer />}
    </div>
  );
}
