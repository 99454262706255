import clsx from 'clsx';
import React from 'react';
import { User } from 'react-feather';
import Link from 'next/link';

export type UserAvatarProps = {
  color?: 'white' | 'brand';
  imageSrc?: string;
  href?: string;
};

function MaybeLinkWrapper({
  href,
  children,
}: {
  href: string | undefined;
  children: React.ReactNode;
}) {
  if (!href) {
    return <>{children}</>;
  }

  return (
    <Link href={href}>
      <a className="flex items-center">{children}</a>
    </Link>
  );
}

export default function UserAvatar({
  color = 'brand',
  imageSrc,
  href,
}: UserAvatarProps) {
  return (
    <MaybeLinkWrapper href={href}>
      <div
        className={clsx(
          'rounded-full w-10 h-10 flex items-center justify-center overflow-hidden shrink-0',
          {
            'bg-brand-500 text-white': !imageSrc && color === 'brand',
            'bg-white text-gray-800': !imageSrc && color === 'white',
          },
        )}
      >
        {imageSrc ? <img src={imageSrc} alt="" /> : <User />}
      </div>
    </MaybeLinkWrapper>
  );
}
