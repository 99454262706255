import type ReactModal from 'react-modal';
import Modal from ':src/components/Modal';
import Button from ':src/components/Button';
import { SURVEY_LOCALSTORAGE_KEY } from ':src/constants';
import * as gtag from ':src/lib/gtag';

export type SurveyModalProps = ReactModal.Props & {
  onRequestClose: () => void;
  isOpen: boolean;
};

export default function SurveyModal({
  onRequestClose,
  ...restProps
}: SurveyModalProps) {
  const handleClose = () => {
    localStorage.setItem(
      SURVEY_LOCALSTORAGE_KEY,
      JSON.stringify({ hide: true }),
    );
    onRequestClose();
  };

  return (
    <Modal
      {...restProps}
      onRequestClose={handleClose}
      title="We'd love your feedback!"
    >
      <div className="max-w-lg">
        <p className="mb-4">
          We’re working hard on making this site the best resource to prepare
          for Frontend interviews.
        </p>
        <p className="mb-8">
          We’d love to hear from you what would be most helpful to you. If you
          have two minutes to spare, it’d be great if you could answer a couple
          of questions!
        </p>

        <div className="flex justify-between">
          <Button type="button" color="slate" onClick={handleClose}>
            No thanks
          </Button>
          <Button
            type="button"
            onClick={() => {
              window.open(
                'https://sbktimxhd8x.typeform.com/to/mMGJi498',
                '_blank',
              );
              gtag.event({
                action: 'modal_cta_click',
                category: 'survey',
                label: 'Click survey modal CTA',
                value: '1',
              });
              handleClose();
            }}
          >
            Take a quick survey
          </Button>
        </div>
      </div>
    </Modal>
  );
}
