import React, { useState } from 'react';
import ReactOutsideClickHandler from 'react-outside-click-handler';
import Link from 'next/link';

export type MenuProps = {
  trigger: React.ReactNode;
  children: React.ReactElement | React.ReactFragment;
  onCloseSuccess?: () => void;
};

export type MenuItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
  href?: string;
};

export function MenuItem({ children, onClick, href }: MenuItemProps) {
  if (href) {
    return (
      <Link href={href}>
        <a>
          <div className="px-4 py-3 block w-full text-left hover:bg-gray-100">
            {children}
          </div>
        </a>
      </Link>
    );
  }
  return (
    <button
      type="button"
      onClick={onClick}
      className="px-4 py-3 block w-full text-left hover:bg-gray-100"
    >
      {children}
    </button>
  );
}

export function MenuDivider() {
  return <hr className="border-t border-gray-400" />;
}

export default function Menu({ trigger, children, onCloseSuccess }: MenuProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleIsOpen = () => setIsOpen(!isOpen);

  const close = () => {
    setIsOpen(false);
    if (onCloseSuccess) {
      onCloseSuccess();
    }
  };

  return (
    <ReactOutsideClickHandler onOutsideClick={close}>
      <div className="relative">
        <button onClick={toggleIsOpen}>{trigger}</button>
        {isOpen && (
          <div
            className="rounded bg-white text-body shadow-lg absolute right-0 w-48 overflow-hidden"
            onClick={close}
            onKeyPress={close}
            role="menu"
            tabIndex={0}
          >
            {children}
          </div>
        )}
      </div>
    </ReactOutsideClickHandler>
  );
}
