import React from 'react';
import ReactModal from 'react-modal';
import { X } from 'react-feather';

export type ModalProps = ReactModal.Props & {
  children: React.ReactNode;
  title: React.ReactNode;
};

export default function Modal({
  children,
  title,
  onRequestClose,
  ...restProps
}: ModalProps) {
  return (
    <ReactModal
      bodyOpenClassName="overflow-hidden"
      className="rounded-lg shadow-lg flex flex-col bg-white p-4 transform max-w-4xl"
      overlayClassName="inset-0 fixed bg-gray-800 bg-opacity-30 flex items-center justify-center"
      onRequestClose={onRequestClose}
      {...restProps}
    >
      <div className="flex">
        <div className="flex-grow text-xl">{title}</div>
        <div className="ml-auto pl-2 flex items-center">
          <button type="button" onClick={onRequestClose}>
            <X />
          </button>
        </div>
      </div>
      <div className="pt-4">{children}</div>
    </ReactModal>
  );
}
