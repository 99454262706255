import { useRouter } from 'next/router';

export default function useFeatureFlags() {
  const router = useRouter();

  const { isMVPLaunched } = router.query;
  return {
    isMVPLaunched:
      isMVPLaunched === 'true' ||
      process.env.NEXT_PUBLIC_IS_MVP_LAUNCHED === 'true' ||
      process.env.NODE_ENV === 'development',
    isFollowEnabled: process.env.NODE_ENV === 'development',
  };
}
