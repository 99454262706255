import React, { useState } from 'react';
import Hamburger from 'hamburger-react';
import { AlertCircle } from 'react-feather';
import { User } from ':src/types';
import Menu, { MenuItem } from './Menu';
import GithubButton from './social/GithubButton';

const STROKE_COLOR = '#F87171';

type NavMobileProps = {
  iconColor: string;
  user?: User;
  handleLogout: () => void;
};

export default function NavMobile({
  user,
  iconColor,
  handleLogout,
}: NavMobileProps) {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <Menu
      trigger={<Hamburger toggled={isOpen} color={iconColor} toggle={toggle} />}
      onCloseSuccess={close}
    >
      <MenuItem href="/">Home</MenuItem>
      <MenuItem href="/about">About</MenuItem>
      <MenuItem href="/resources">Resources</MenuItem>
      {!user && <GithubButton actionText="Log in" mobile />}
      {user && <MenuItem href="/account">Account</MenuItem>}
      {user && (
        <MenuItem
          href={user.username ? `/profile/${user.username}` : '/account'}
        >
          {user.username ? (
            <span>Profile</span>
          ) : (
            <span className="flex">
              <AlertCircle className="mr-2" color={STROKE_COLOR} />
              Profile
            </span>
          )}
        </MenuItem>
      )}
      {user && <MenuItem onClick={handleLogout}>Sign out</MenuItem>}
    </Menu>
  );
}
