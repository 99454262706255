class APIError extends Error {
  apiErrors: string[];

  constructor(error: string, apiErrors: string[] = []) {
    super(error);
    this.apiErrors = apiErrors;
  }
}

export const isAPIError = (err: any): err is APIError => {
  return 'apiErrors' in err;
};

type JsonSerializable =
  | null
  | string
  | number
  | boolean
  | JsonSerializable[]
  | { [key: string]: JsonSerializable };

export default async function apiFetch<ReturnType>(
  path: string,
  method: RequestInit['method'] = 'GET',
  body?: JsonSerializable,
): Promise<ReturnType | null> {
  const hostname =
    process.env.NODE_ENV === 'production'
      ? 'https://api.frontendeval.com'
      : 'https://localhost:3001';
  const response = await fetch(`${hostname}/${path}`, {
    method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    body: body ? JSON.stringify(body) : undefined,
  });
  if (!response.ok) {
    const { errors } = JSON.parse(await response.text()) as {
      errors: string[];
    };
    throw new APIError(
      `Failed to fetch ${path}, response=${errors.join(', ')}`,
      errors,
    );
  }
  if (response.status === 204) {
    return null;
  }
  return response.json() as Promise<ReturnType>;
}
